import React from "react"
import { useHistory } from "react-router"
import { PageTitle } from "../../molecules/page-title"
import { Shipments } from "../../organims/user-shipments"
import { MainHome, MainUserInfo, MainUserInfoInner } from "./styled"
import { ChangeCurrencyForm } from "../../organims/add-user-balance-form"
export const UserDetail = (props) => {
  const user = props.location.state
  const history = useHistory()
  if (!props.location.state) {
    history.push("/users")
    return (
      <div style={{ margin: "auto 50%" }}>
        <p>Cargando...</p>
      </div>
    )
  }
  console.log(user)
  return (
    <MainHome>
      <PageTitle title={`Información sobre usuarios`} />
      <h1>{`Información sobre: ${user.firstName}`},</h1>
      <p>
        Este es tu panel administrativo donde podrás hacer la configuración y la
        supervisión de toda tu operación.
      </p>
      <MainUserInfo>
        <p>Nombre:</p>
        <p>{`${user.firstName}, ${user.lastName}`}</p>
        <p>Correo electrónico</p>
        <p>{user.email}</p>
        <p>Recibir correos</p>
        <p>{user.receiveEmails ? "Si" : "No"}</p>
        <p>Fecha de registro</p>
        <p>{new Date(user.createdAt).toLocaleString()}</p>
        <p>Nombre de empresa:</p>
        <p>{`${user?.enterpriseName}`}</p>
        <p>Documento:</p>
        <p>{`${user?.documentType} ${user?.document}`}</p>
        <p>Núm. de empleados:</p>
        <p>{`${user?.employeesNumber}`}</p>
        <p>Industría:</p>
        <p>{`${user?.industryTarget}`}</p>
        <p>Envíos Int:</p>
        <p>{`${user?.internationalShipmentsEstimated}`}</p>
        <p>Envíos Nacional:</p>
        <p>{`${user?.nationalShipmentsEstimated}`}</p>
        <p>Phone:</p>
        <p>{user.phone}</p>
        <p>Politicas:</p>
        <p>
          {user.acceptedPolicies.length === 0
            ? 1
            : user.acceptedPolicies.length}
        </p>
        <p>Activo</p>
        <p>
          {user.isActive === null
            ? "Activo"
            : user.isActive === true
            ? "Activo"
            : "Inactive"}
        </p>
      </MainUserInfo>
      <MainUserInfoInner>
        <ChangeCurrencyForm balance={user.balanceAvailable} user={user._id} />
      </MainUserInfoInner>
      {/* Info importante */}
      <Shipments id={user._id} />
    </MainHome>
  )
}
