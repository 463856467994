import React from 'react';
import { ErrorMessage, useField } from 'formik';
import { DatePicker } from 'antd';
import { ErrorText } from './styles';

export const FormikDate = ({ label, ...props }) => {
  const [field, , helper] = useField(props);
  return (
    <div>
      {label && <label>{label}</label>}
      <DatePicker {...field} {...props} onChange={helper.setValue} />
      <ErrorMessage name={field.name}>
        {(msj) => <ErrorText>{msj}</ErrorText>}
      </ErrorMessage>
    </div>
  );
};
