import { Button, Modal } from 'antd';
import { Formik } from 'formik';
import React from 'react';
// import { useDispatch, useSelector } from 'react-redux';
// import { changedAmountConfig } from '../../../store/config/config.actions';
// import { getConfigAmountSelector } from '../../../store/config/config.selectors';
import { FormikInput } from '../../molecules/formik-input-field';
import { FormWrapper } from './styles';
import { schemaChardeBalance } from './validation';
import { ApiService } from '../../../services';

export const ChangeCurrencyForm = () => {
  const [currentExchange, setCurrentExchange] = React.useState(0);
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [message, setMessage] = React.useState('');

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
  React.useEffect(() => test(), [])
  const test = async () => {
    try {
      const { data } = await ApiService.PricingService.getLastCurrency();
      setCurrentExchange(data.data.currencyUSD)
      // console.log(data);
    } catch (error) {
      console.log(error);
    }
  };
  // const dispatch = useDispatch();
  // const currency = useSelector(getConfigAmountSelector);

  const test1 = async (amount, actions) => {
    try {
      const { data } = await ApiService.PricingService.putNewCurrency(amount);
      setMessage(data);
      if (data.statusCode === 201) {
        test();
        const res = await ApiService.PricingService.getLastCurrency();
        setCurrentExchange(res.data.data.currencyUSD)
        setMessage(res.data)
        actions.setSubmitting(false);
      }
      showModal();
    } catch (error) {
      console.log(error);
    }
  }; 

  return (
    <>
    <Formik
      initialValues={{
        amount: '',
      }}
      enableReinitialize
      onSubmit={({amount}, actions) => {
        //console.log(amount, actions)
        test1(amount, actions)
        // dispatch(changedAmountConfig(values, actions.resetForm));
      }}
      validationSchema={schemaChardeBalance}
    >
      {({ isValid, handleSubmit, submitForm, isSubmitting }) => (
        <FormWrapper onSubmit={handleSubmit}>
          <h1>Tasa actual: 1 USD = {currentExchange} COP</h1>
          <p className="text-info">Valor del currency</p>
          <FormikInput
            name="amount"
            style={{ width: '100%' }}
            type="number"
            placeholder="Ej: 123abc456def"
          />

          <div className="submit-button">
            <Button
              type="primary"
              ghost
              onClick={submitForm}
              disabled={!isValid && !isSubmitting}
              block
            >
              {!isSubmitting ? 'Confirmar' : 'Cargando...'}
            </Button>
          </div>
        </FormWrapper>
      )}
    </Formik>
    <Modal title={message.statusCode === 201 ? 'Exito' : 'Error'} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
      <p>Se ha actualizado el trm a: {message.data?.currencyUSD}</p>
    </Modal>
    </>
  );
};
