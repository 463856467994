import React from 'react';
import { Formik } from 'formik';
import { Button, message, Popconfirm } from 'antd';

import { FormikInput } from '../../molecules/formik-input-field';
import { FormikSelected } from '../../molecules/formik-selected';

import { schemaChardeBalance } from './validation';
import { ApiService } from '../../../services';
import { FormWrapper } from './styles';

export const ChangeCurrencyForm = ({ balance, user }) => {
  const handleSubmit = async (values, actions) => {
    const body = {
      user,
      type: values.type,
      balance: values.balance,
      currency: 'COP',
      bank: 'Shipal',
    };

    try {
      const { data } = await ApiService.WalletService.addBudget(body);

      message.info(`${data.transaction}: ${data.message}`);
    } catch (error) {
      console.log(error);
    }

    actions.resetForm();
  };

  const transactionTypes = {
    credit: 'CRED',
    debit: 'DEB',
  };

  const options = [
    {
      label: 'abonar saldo',
      value: transactionTypes['credit'],
    },
    {
      label: 'debitar saldo',
      value: transactionTypes['debit'],
    },
  ];

  const transactionLabel = {
    [transactionTypes.credit]: 'abonar',
    [transactionTypes.debit]: 'debitar',
  };

  return (
    <>
      <Formik
        initialValues={{
          balance: 0,
          type: '',
        }}
        enableReinitialize
        onSubmit={handleSubmit}
        validationSchema={schemaChardeBalance}
      >
        {({ isValid, handleSubmit, submitForm, resetForm, values, dirty }) => (
          <FormWrapper onSubmit={handleSubmit}>
            <h1>Balance: {balance} COP</h1>

            <FormikSelected
              name="type"
              options={options}
              label="Tipo de movimiento"
            />

            <p className="text-info">Cantidad:</p>
            <FormikInput
              name="balance"
              style={{ width: '100%' }}
              type="number"
              placeholder="20000"
            />

            <Popconfirm
              title={`Estas seguro que deseas ${
                transactionLabel[values.type]
              } $${values.balance} COP`}
              onConfirm={() => submitForm()}
              onCancel={() => resetForm()}
              okText="Si"
              cancelText="No"
              disabled={!isValid || !dirty}
            >
              <Button disabled={!isValid || !dirty} type="primary" ghost>
                Realizar transaccion
              </Button>
            </Popconfirm>
          </FormWrapper>
        )}
      </Formik>
    </>
  );
};
