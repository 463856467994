import React from "react"
import { Provider } from "react-redux"
import { PersistGate } from "redux-persist/integration/react"
import { AppStore, history, persistor } from "./store"
import { applyInterceptors } from "./utils/interceptors"
import { ConnectedRouter } from "connected-react-router"
import { AppRoutes } from "./routes"
import { ToastContainer } from "react-toastify"

/* Test CI */
applyInterceptors(AppStore)
const App = () => {
  return (
    <Provider store={AppStore}>
      <PersistGate persistor={persistor}>
        <ConnectedRouter history={history}>
          <AppRoutes />
          <ToastContainer
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            draggable
            pauseOnFocusLoss={false}
          />
        </ConnectedRouter>
      </PersistGate>
    </Provider>
  )
}

export default App
