import React, { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { utcToZonedTime } from "date-fns-tz"
import { Formik, Form } from "formik"
import { TableCustom } from "../../organims/table"
import { WrapperShipments } from "./styled"
import { PageTitle } from "../../molecules/page-title"
import { FormikDate } from "../../molecules/formik-date-field"
import { ApiService } from "../../../services"
import { Space, Button, Spin } from "antd"
import { PickUpForm } from "../../forms/pickup"
import iconDHL from "../../../assets/img/iconDHL.png"
import iconCOO from "../../../assets/img/iconCoordinadora.png"
import iconFEDEX from "../../../assets/img/logoFedex.png"

export const Shipments = () => {
  const dispatch = useDispatch()
  const [page, setPage] = useState(1)
  const [limit, setLimit] = useState(50)
  const [totalDocs, setTotalDocs] = useState(5)
  const [envios, setEnvios] = useState()
  function taskDate(date) {
    // console.log('date', date, moment(date).tz("America/Bogota").toDate(), moment(date).toDate().toISOString());
    var d = utcToZonedTime(date, "America/Bogota"),
      month = "" + (d.getMonth() + 1),
      day = "" + d.getDate(),
      year = d.getFullYear()

    if (month.length < 2) month = "0" + month
    if (day.length < 2) day = "0" + day

    return [year, month, day].join("-")
  }
  const handlePagination = (pagination) => {
    test(pagination)
  }
  useEffect(() => {
    dispatch({
      type: "test",
      success: {
        id: "custom",
      },
    })
    test(page)
  }, [dispatch])
  const test = async (indexPage, date = null) => {
    try {
      const { data } = await ApiService.ShipmentsService.shipments(
        indexPage,
        limit,
        date
      )
      // console.log(data);
      setPage(data.data.page)
      setTotalDocs(data.data.totalDocs)
      setEnvios(
        data.data.docs.map((ship, index) => {
          return {
            ...ship,
            guideId:
              ship.carrier !== "COORDINADORA"
                ? ship.orderResponse?.AirwayBillNumber
                : ship.orderResponse?.ConfirmationNumber,
            user: ship.user?.firstName || "N/A",
            trm: `$${ship.trmUsed}COP` || "N/A",
            payableWeight:
              parseFloat(ship?.shipal?.PayableWeight).toFixed(1) || "N/A",
            totalPayment: `${ship.totalPayment} COP` || "N/A",
            ratePayForShipment: `${ship.ratePayForShipment} COP` || "N/A",
            consigneeInfo: ship.orderInfo?.Consignee.AddressLine1 || "N/A",
            origin: `${ship.orderInfo?.Shipper.City},${ship.orderInfo?.Shipper.CountryName} `,
            destination: `${ship.orderInfo?.Consignee.City},${ship.orderInfo?.Consignee.CountryName} `,
            insurence: ship?.shipal?.Isnurrence
              ? `$${ship.shipal?.IsnurrenceValue || 0}COP`
              : "Sin seguro",
            startDate: new Date(ship.createdAt).toLocaleString() || "N/A",
            key: ship.id || Math.random(),
            transportadores: "dhl" || "N/A",
          }
        })
      )
    } catch (error) {
      console.log(error)
    }
  }
  const columns = [
    /* {
      title: 'Consecutivo',
      dataIndex: 'userConsecutive',
      responsive: ['md'],
    }, */
    {
      title: "Creada",
      dataIndex: "startDate",
      responsive: ["md"],
    },
    {
      title: "Usuario",
      dataIndex: "user",
      responsive: ["md"],
    },
    {
      title: "Guía",
      dataIndex: "guideId",
      responsive: ["md"],
    },
    {
      title: "Valor COP",
      dataIndex: "totalPayment",
      responsive: ["md"],
    },
    {
      title: "Peso a cobrar (Kg)",
      dataIndex: "payableWeight",
      responsive: ["md"],
    },
    {
      title: "Origen",
      dataIndex: "origin",
      responsive: ["md"],
    },
    {
      title: "Destino",
      dataIndex: "destination",
      responsive: ["md"],
    },
    {
      title: "Asegurado",
      dataIndex: "insurence",
      responsive: ["md"],
    },
    {
      title: "Carrier",
      key: "action",
      render: (text, record) => {
        return (
          <img
            src={
              record.carrier === "COORDINADORA"
                ? iconCOO
                : record.carrier === "DHL"
                ? iconDHL
                : iconFEDEX
            }
            alt="imagen dhl"
            height={30}
          />
        )
      },
      responsive: ["md"],
    },
    {
      title: "Carrier Fee (USD)",
      key: "action",
      render: (text, record) => (
        <p>{`${(Math.random() * 100).toFixed(2)}USD-Q`}</p>
      ),
      responsive: ["md"],
    },
    {
      title: "TRM Aplicado",
      dataIndex: "trm",
      responsive: ["md"],
    },
    {
      title: "Action",
      key: "action",
      render: (text, record) => (
        <Space size="middle">
          <Button
            onClick={() =>
              window
                .open(
                  `https://www.dhl.com/us-en/home/tracking/tracking-express.html?submit=1&tracking-id=${record.guideId}`,
                  "_blank"
                )
                .focus()
            }
          >
            Tracking
          </Button>
          {record.hasPickup ? (
            <p>Pick-up programada</p>
          ) : (
            <PickUpForm record={record} update={() => test(page)}>
              Pick-up
            </PickUpForm>
          )}
        </Space>
      ),
      fixed: "right",
      responsive: ["md"],
    },
  ]
  if (!envios)
    return (
      <div style={{ margin: "auto 50%" }}>
        <Spin size="Large" />
      </div>
    )
  console.log(envios)
  return (
    <WrapperShipments>
      <PageTitle title="Envíos" />
      <p>
        En envíos se podrá supervisar el historial de envios y hacer acciones
        como traking, solicitar recolección, cancelar, filtrar por variables o
        por número de guias...
      </p>
      <Formik
        initialValues={{
          date: "",
        }}
        onSubmit={async (values) => {
          setPage(1)
          test(1, taskDate(new Date(values.date)))
        }}
      >
        {({ handleSubmit, values }) => (
          <Form>
            <FormikDate name="date" />
            {/* <Field id="date" type="date" name="date" /> */}
            {console.log(
              values.date !== "" ? taskDate(new Date(values.date)) : "",
              "date"
            )}
            <Button
              type="button"
              onClick={handleSubmit}
              // ghost
              // disabled={!isValid}
              // block
            >
              Filtrar por fecha
            </Button>
            <Button
              type="button"
              // ghost
              onClick={() => {
                setPage(1)
                test(1)
              }}
              // disabled={!isValid}
              // block
            >
              Quitar filtro de fecha
            </Button>
          </Form>
        )}
      </Formik>
      <TableCustom
        columns={columns}
        data={envios}
        searchableTable={false}
        searchablePlaceholder="Búsqueda de guía"
        serachableObjKey="guideId"
        searchableTable2={false}
        searchablePlaceholder2="Búsqueda por usuario"
        serachableObjKey2="user"
        csvColumns={[
          "startDate",
          "user",
          "guideId",
          "totalPayment",
          "payableWeight",
          "transportadores",
          "origin",
          "destination",
          "insurence",
          "trm",
        ]}
        current={page}
        handlePagination={handlePagination}
        total={totalDocs}
        limit={limit}
      />
    </WrapperShipments>
  )
}
