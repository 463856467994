import React from 'react';
import { ErrorMessage, useField } from 'formik';
import { Select } from 'antd';
import { ErrorText, FieldWrapper } from './styles';

export const FormikSelected = ({ label, options, ...props }) => {
  const [field, , helper] = useField({ name: props.name });
  const handleChange = (values, t) => {
    console.log(values, t);
    helper.setValue(values);
  };
  return (
    <FieldWrapper>
      {label && <label>{label}</label>}
      <Select {...props} {...field} onChange={handleChange} value={field.value}>
        {options.map(({ value, label }, index) => (
          <Select.Option value={value} key={index}>
            {label}
          </Select.Option>
        ))}
      </Select>
      <ErrorMessage name={field.name}>
        {(msj) => <ErrorText>{msj}</ErrorText>}
      </ErrorMessage>
    </FieldWrapper>
  );
};
