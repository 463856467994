import React from 'react';
import { FormWrapper, FormInput } from './styles';
import { Formik } from 'formik';
import { FormikSelected } from '../../molecules/formik-selected';
import { Button, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { ApiService } from '../../../services';

export const UploadCountriesForm = ({ national = true }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [message, setMessage] = React.useState('');

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };

  const handleEndpointZonesNational = async (values) => {
    try {
      const { data } = await ApiService.PricingService.uploadNationalZones(values);
      setMessage(data);
      showModal()
    } catch (error) {
      console.log(error);
    }
  }

  const handleEndpointZonesInternational = async (values) => {
    try {
      const { data } = await ApiService.PricingService.uploadZones(values);
      setMessage(data);
      showModal()
    } catch (error) {
      console.log(error);
    }
  }
    return (
      <>
        <Formik
        initialValues={{
          file: null,
          type: 'DHL'
        }}
        onSubmit={async (values) => {
          national ? handleEndpointZonesNational(values) : handleEndpointZonesInternational(values);
          console.log(values);
        }}
      >
        {({ values, submitForm, setFieldValue }) => (
        <FormWrapper>
          <h1>Subir nuevos códigos por zonas  {national ? 'nacionales' : 'internacionales'}</h1>
          <FormikSelected
            label="Selecciona Currier"
            name="type"
            options={[{label: 'DHL', value: 'DHL'}, {label: 'FEDEX', value: 'FEDEX'}]}
          />
          <p className="text-info">Subir archivo para actualizar parametros de cotización por país.</p>
          <FormInput>
            <input id="file" name="file" type="file" onChange={(event) => {
                setFieldValue("file", event.currentTarget.files[0]);
            }} />
            <UploadOutlined />
            Subir archivo
          </FormInput>
          {values.file && (<><br /><p>{values.file.name}</p></>)}
          <br />
          {!national && <FormInput>
            <input id="file2" name="file2" type="file" onChange={(event) => {
                setFieldValue("file2", event.currentTarget.files[0]);
            }} />
            <UploadOutlined />
            Subir archivo de DHL
          </FormInput>}
          <br />
          {values.file2 && <p>{values.file2.name}</p>}
          <Button 
            // type="button"
            onClick={submitForm}
            type="primary"
            ghost
          >
            Confirmar
          </Button>
        </FormWrapper>)}
      </Formik>
      <Modal title={message.statusCode === 201 ? 'Exito' : 'Error'} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
          <p>{message.message}</p>
        </Modal>
      </>
    )
};
