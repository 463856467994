import React from 'react';
import { FormWrapper, FormInput } from './styles';
import { Formik } from 'formik';
import { Button, Modal } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { FormikSelected } from '../../molecules/formik-selected';
import { ApiService } from '../../../services';

export const UploadZonesForm = ({ national = true }) => {
  const [isModalVisible, setIsModalVisible] = React.useState(false);
  const [message, setMessage] = React.useState('');

  const showModal = () => {
    setIsModalVisible(true);
  };

  const handleOk = () => {
    setIsModalVisible(false);
  };

  const handleCancel = () => {
    setIsModalVisible(false);
  };
    return (
      <>
        <Formik
        initialValues={{
          national,
          type: 'DHL'
        }}
        onSubmit={async (values) => {
            try {
              const { data } = await ApiService.PricingService.uploadExcel(values);
              setMessage(data);
              showModal()
            } catch (error) {
              console.log(error);
            }
        }}
      >
        {({ values, submitForm, setFieldValue }) => (
        <FormWrapper>
          <h1>Subir nuevas tarifas {national ? 'nacionales' : 'internacionales'}</h1>
          <FormikSelected
            label="Selecciona Currier"
            name="type"
            options={[{label: 'DHL', value: 'DHL'}, {label: 'FEDEX', value: 'FEDEX'}]}
          />
          <p className="text-info">Subir archivo para actualizar parametros de cotización.</p>
          <FormInput>
            <input id="file" name="file" type="file" onChange={(event) => {
                setFieldValue("file", event.currentTarget.files[0]);
            }} />
            <UploadOutlined />
            Subir archivo {national ? 'nacionales' : 'internacionales'}
          </FormInput>
          <br />
          {values.file && <p>{values.file.name}</p>}
          <Button 
            // type="button"
            onClick={submitForm}
            type="primary"
            ghost
          >
            Confirmar
          </Button>
        </FormWrapper>)}
      </Formik>
        <Modal title={message.statusCode === 201 ? 'Exito' : 'Error'} visible={isModalVisible} onOk={handleOk} onCancel={handleCancel}>
          <p>{message.message}</p>
        </Modal>
      </>
    )
};
